import type { RouterConfig } from '@nuxt/schema'

export default (<RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: _routes => [
    {
      name: 'home',
      path: '/',
      component: () => import('~/pages/home.vue').then(m => m.default || m)
    },
    {
      name: 'nagy-kerdesek',
      path: '/nagy-kerdesek',
      component: () =>
        import('~/pages/nagy-kerdesek').then(m => m.default || m),
      props: {
        categoryId: 3
      }
    },
    {
      name: 'csupa-magyar',
      path: '/csupa-magyar',
      component: () =>
        import('~/pages/csupa-magyar').then(m => m.default || m),
      props: {
        categoryId: 2
      }
    },
    {
      name: 'napi-csaj',
      path: '/napi-csaj',
      component: () => import('~/pages/napi-csaj').then(m => m.default || m),
      props: {
        categoryId: 11
      }
    },
    {
      name: 'piramisok-vilaga',
      path: '/piramisok-vilaga',
      component: () =>
        import('~/pages/piramisok-vilaga').then(m => m.default || m),
      props: {
        categoryId: 5
      }
    },
    {
      name: 'kvizek',
      path: '/kvizek',
      component: () => import('~/pages/kvizek').then(m => m.default || m),
      props: {
        categoryId: 4
      }
    },
    {
      name: 'rejtelyes-fold',
      path: '/rejtelyes-fold',
      component: () =>
        import('~/pages/rejtelyes-fold').then(m => m.default || m),
      props: {
        categoryId: 10
      }
    },
    {
      name: 'csalamade',
      path: '/csalamade',
      component: () => import('~/pages/csalamade').then(m => m.default || m),
      props: {
        categoryId: 12
      }
    },
    {
      name: 'hashtag',
      path: '/hashtag/:tag',
      component: () => import('~/pages/hashtag').then(m => m.default || m)
    },
    {
      name: 'szerzo',
      path: '/szerzo/:authorSlug',
      component: () => import('~/pages/kereses').then(m => m.default || m)
    },
    {
      name: 'kereses',
      path: '/kereses',
      component: () => import('~/pages/kereses').then(m => m.default || m)
    },
    {
      name: 'felhasznalasi-feltetelek',
      path: '/felhasznalasi-feltetelek',
      component: () =>
        import('~/pages/felhasznalasi-feltetelek').then(m => m.default || m)
    },
    {
      name: 'szerzoi-jogok',
      path: '/szerzoi-jogok',
      component: () =>
        import('~/pages/szerzoi-jogok').then(m => m.default || m)
    },
    {
      name: 'rolunk',
      path: '/rolunk',
      component: () => import('~/pages/rolunk').then(m => m.default || m)
    },
    {
      name: 'impresszum',
      path: '/impresszum',
      component: () => import('~/pages/impresszum').then(m => m.default || m)
    },
    {
      name: 'cookie-hasznalat',
      path: '/cookie-hasznalat',
      component: () =>
        import('~/pages/cookie-hasznalat').then(m => m.default || m)
    },
    {
      name: 'adatvedelem',
      path: '/adatvedelem',
      component: () =>
        import('~/pages/adatvedelem').then(m => m.default || m)
    },
    {
      name: 'article-post',
      path: '/:category/:year([0-9]+)/:month([0-9]+)/:day([0-9]+)/:slug',
      component: () =>
        import('~/pages/article-post').then(m => m.default || m)
    }
  ]
}) satisfies RouterConfig
