import { defineStore } from 'pinia'

export const useAdStore = defineStore('ad', () => {
  const isAlternativeAds = ref(false)

  const setAlternativeAds = (value: boolean) => {
    isAlternativeAds.value = value
  }

  return {
    isAlternativeAds,
    setAlternativeAds
  }
})
