import { default as adatvedelemSVS8vwzjzdMeta } from "/srv/nodejs/pages/adatvedelem.vue?macro=true";
import { default as article_45postytH2cBhp9rMeta } from "/srv/nodejs/pages/article-post.vue?macro=true";
import { default as cookie_45hasznalatMcdYRrI3xJMeta } from "/srv/nodejs/pages/cookie-hasznalat.vue?macro=true";
import { default as csalamadeURay7cCTnUMeta } from "/srv/nodejs/pages/csalamade.vue?macro=true";
import { default as csupa_45magyarcmU62tmJYiMeta } from "/srv/nodejs/pages/csupa-magyar.vue?macro=true";
import { default as felhasznalasi_45feltetelekofr9dx6hPhMeta } from "/srv/nodejs/pages/felhasznalasi-feltetelek.vue?macro=true";
import { default as hashtagHvQ5jh50TuMeta } from "/srv/nodejs/pages/hashtag.vue?macro=true";
import { default as homegds4B9h71OMeta } from "/srv/nodejs/pages/home.vue?macro=true";
import { default as impresszumhCuAiPSjOEMeta } from "/srv/nodejs/pages/impresszum.vue?macro=true";
import { default as keresesGzx2GvucpDMeta } from "/srv/nodejs/pages/kereses.vue?macro=true";
import { default as kvizekgyXDbqdhojMeta } from "/srv/nodejs/pages/kvizek.vue?macro=true";
import { default as nagy_45kerdesekHKsRbeSM2gMeta } from "/srv/nodejs/pages/nagy-kerdesek.vue?macro=true";
import { default as napi_45csajwIl3Z7Vmo6Meta } from "/srv/nodejs/pages/napi-csaj.vue?macro=true";
import { default as piramisok_45vilagaJqOl1JlUEuMeta } from "/srv/nodejs/pages/piramisok-vilaga.vue?macro=true";
import { default as rejtelyes_45foldz4t6HB7GB0Meta } from "/srv/nodejs/pages/rejtelyes-fold.vue?macro=true";
import { default as rolunkwZaetPVPBoMeta } from "/srv/nodejs/pages/rolunk.vue?macro=true";
import { default as szerzoi_45jogokieToOFToMNMeta } from "/srv/nodejs/pages/szerzoi-jogok.vue?macro=true";
export default [
  {
    name: "adatvedelem",
    path: "/adatvedelem",
    component: () => import("/srv/nodejs/pages/adatvedelem.vue")
  },
  {
    name: "article-post",
    path: "/article-post",
    component: () => import("/srv/nodejs/pages/article-post.vue")
  },
  {
    name: "cookie-hasznalat",
    path: "/cookie-hasznalat",
    component: () => import("/srv/nodejs/pages/cookie-hasznalat.vue")
  },
  {
    name: "csalamade",
    path: "/csalamade",
    component: () => import("/srv/nodejs/pages/csalamade.vue")
  },
  {
    name: "csupa-magyar",
    path: "/csupa-magyar",
    component: () => import("/srv/nodejs/pages/csupa-magyar.vue")
  },
  {
    name: "felhasznalasi-feltetelek",
    path: "/felhasznalasi-feltetelek",
    component: () => import("/srv/nodejs/pages/felhasznalasi-feltetelek.vue")
  },
  {
    name: "hashtag",
    path: "/hashtag",
    component: () => import("/srv/nodejs/pages/hashtag.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/srv/nodejs/pages/home.vue")
  },
  {
    name: "impresszum",
    path: "/impresszum",
    component: () => import("/srv/nodejs/pages/impresszum.vue")
  },
  {
    name: "kereses",
    path: "/kereses",
    component: () => import("/srv/nodejs/pages/kereses.vue")
  },
  {
    name: "kvizek",
    path: "/kvizek",
    component: () => import("/srv/nodejs/pages/kvizek.vue")
  },
  {
    name: "nagy-kerdesek",
    path: "/nagy-kerdesek",
    component: () => import("/srv/nodejs/pages/nagy-kerdesek.vue")
  },
  {
    name: "napi-csaj",
    path: "/napi-csaj",
    component: () => import("/srv/nodejs/pages/napi-csaj.vue")
  },
  {
    name: "piramisok-vilaga",
    path: "/piramisok-vilaga",
    component: () => import("/srv/nodejs/pages/piramisok-vilaga.vue")
  },
  {
    name: "rejtelyes-fold",
    path: "/rejtelyes-fold",
    component: () => import("/srv/nodejs/pages/rejtelyes-fold.vue")
  },
  {
    name: "rolunk",
    path: "/rolunk",
    component: () => import("/srv/nodejs/pages/rolunk.vue")
  },
  {
    name: "szerzoi-jogok",
    path: "/szerzoi-jogok",
    component: () => import("/srv/nodejs/pages/szerzoi-jogok.vue")
  }
]