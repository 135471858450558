import revive_payload_client_4sVQNw7RlN from "/srv/nodejs/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/srv/nodejs/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/srv/nodejs/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/srv/nodejs/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/srv/nodejs/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/srv/nodejs/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/srv/nodejs/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/srv/nodejs/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/nodejs/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/srv/nodejs/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/srv/nodejs/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/srv/nodejs/.nuxt/sentry-client-config.mjs";
import plugin_iGLjBbor9q from "/srv/nodejs/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/srv/nodejs/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/srv/nodejs/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/srv/nodejs/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import adsense_client_7GvfU3TIPa from "/srv/nodejs/plugins/adsense.client.ts";
import api_GFfDXud5Cr from "/srv/nodejs/plugins/api.ts";
import gtag_client_Zw8EQXNVTz from "/srv/nodejs/plugins/gtag.client.ts";
import inmobi_client_tkkINF5dGT from "/srv/nodejs/plugins/inmobi.client.ts";
import plausible_client_QgwkVYmaxk from "/srv/nodejs/plugins/plausible.client.ts";
import vue_easy_lightbox_client_lWqeizDQUL from "/srv/nodejs/plugins/vue-easy-lightbox.client.ts";
import vue_select_client_vyBhfMYE5L from "/srv/nodejs/plugins/vue-select.client.ts";
import plugin_client_QHzhVBB2Ll from "/srv/nodejs/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_iGLjBbor9q,
  plugin_Jozdw60ZsE,
  plugin_PSbK9A48ZQ,
  plugin_t2GMTTFnMT,
  adsense_client_7GvfU3TIPa,
  api_GFfDXud5Cr,
  gtag_client_Zw8EQXNVTz,
  inmobi_client_tkkINF5dGT,
  plausible_client_QgwkVYmaxk,
  vue_easy_lightbox_client_lWqeizDQUL,
  vue_select_client_vyBhfMYE5L,
  plugin_client_QHzhVBB2Ll
]