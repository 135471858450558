export default defineNuxtPlugin(() => {
  useHead({
    script: [
      {
        src: 'https://plausible.maxinewz.hu/js/script.js',
        defer: true,
        'data-domain': 'maxinewz.hu'
      }
    ]
  })
})
