export default defineNuxtPlugin(() => {
  useHead({
    script: [
      {
        src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2322675379115242',
        async: true,
        crossorigin: 'anonymous'
      }
    ]
  })
})
