export default defineNuxtRouteMiddleware((to, _from) => {
  if (import.meta.client) { return }
  const { getCategoryByName } = useCategoryStore()
  if (to.params?.slug !== undefined && to.name === 'article-post') {
    const isCategory = getCategoryByName(to.params.slug)
    if (isCategory !== undefined) {
      return navigateTo(`/${isCategory.slug}`, { redirectCode: 301 })
    }
  }
})
